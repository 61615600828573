import axios from "axios";
import { firebase } from "../firebase";
import { AES, enc } from "crypto-js";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(async (req) => {
  const token = await firebase.auth().currentUser.getIdToken(true);
  req.headers.Authorization = `Bearer ${token}`;
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    const bytes = AES.decrypt(res.data, process.env.REACT_APP_SAUCE);
    const data = JSON.parse(bytes.toString(enc.Utf8));
    return data;
  },
  async (error) => {
    const bytes = AES.decrypt(error.response.data, process.env.REACT_APP_SAUCE);
    const data = JSON.parse(bytes.toString(enc.Utf8));
    throw data;
  }
);

export { axiosInstance };
