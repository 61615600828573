import React from "react";
import clsx from "clsx";
import { makeStyles, Dialog } from "@material-ui/core";
import { grayBlue, scrollbarNone } from "./styles";

const CoModalLayout = (props) => {
  const classes = useStyles();
  const {
    open,
    closeModal,
    fullScreen = false,
    fullWidth = false,
    maxWidth = "md",
    children,
    background = "grayBlue",
    setNoHeight = false,
    transparentBackdrop = true,
    zIndex = false,
    blur = true,
  } = props;

  return (
    <Dialog
      className={clsx(
        classes["root"],
        { [classes["root-blur"]]: blur },
        { [classes["root-z-index"]]: zIndex },
        { [classes["root-full-screen"]]: fullScreen }
      )}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={closeModal}
      BackdropProps={{
        classes: {
          root: clsx({
            [classes["modal-backdrop"]]: transparentBackdrop,
          }),
        },
      }}
      PaperProps={{
        classes: {
          root: clsx({
            [classes["modal-paper-fullscreen"]]: fullScreen,
            [classes["modal-paper"]]: !fullScreen,
            [classes["modal-paper-no-height"]]: setNoHeight,
            [classes["modal-paper-background-gray-blue"]]:
              background === "grayBlue",
            [classes["modal-paper-background-transparent"]]:
              background === "transparent",
            [classes["modal-paper-background-white"]]: background === "white",
          }),
        },
      }}
    >
      {children}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0`,
    backgroundColor: "transparent",
    // backdropFilter: `blur(${theme.spacing(1)}px)`,
    // [theme.breakpoints.up('md')]: {
    // 	padding: `${theme.spacing(10)}px ${theme.spacing(6)}px`
    // }
  },

  "root-blur": {
    backdropFilter: `blur(${theme.spacing(1)}px)`,
  },

  "root-z-index": {
    zIndex: "1501 !important",
  },

  "root-full-screen": {
    padding: 0,
  },

  "modal-backdrop": {
    backgroundColor: "transparent",
  },

  "modal-paper-fullscreen": {
    boxShadow: "none",
  },

  "modal-paper": {
    boxShadow: "none",
    borderRadius: theme.spacing(2.5),
    height: "100%",
    ...scrollbarNone,
  },

  "modal-paper-no-height": {
    height: "unset",
  },

  "modal-paper-background-gray-blue": {
    backgroundColor: grayBlue,
  },

  "modal-paper-background-white": {
    backgroundColor: "white",
  },

  "modal-paper-background-transparent": {
    backgroundColor: "transparent",
  },
}));

export { CoModalLayout };
