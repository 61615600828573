import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { makeStyles, AppBar, Toolbar } from "@material-ui/core";
import { NavbarEditingWarning } from "./NavbarEditingWarning";
import { CoButton, CoLoader } from "../shared/sharedComponents";
import { navy, navyGray } from "../shared/styles";
import { logout } from "../../redux/actions/authActions";

const Navbar = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((state) => state.app.get("user"));
  const orders = useSelector((state) => state.orders);

  const isEditingOrder = orders.get("isEditingOrder");
  const shouldGenerateOrderForm = orders.get("shouldGenerateOrderForm");
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [showIsEditingWarning, setShowIsEditingWarning] = useState(false);

  const handleLogout = async () => {
    // if (isEditingOrder) { // use this when save resumes
    if (shouldGenerateOrderForm || isEditingOrder) {
      setShowIsEditingWarning(true);
    } else {
      setIsLoggingOut(true);
      await logout();
      window.location.href = "/login";
    }
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    // if (isEditingOrder) { // use this when save resumes
    if (shouldGenerateOrderForm || isEditingOrder) {
      setShowIsEditingWarning(true);
    } else {
      history.push(`/${e.target.name}`);
    }
  };

  const handleCloseWarning = () => {
    setShowIsEditingWarning(false);
  };

  return (
    <div className={classes["root"]}>
      {user && (
        <AppBar
          position="static"
          classes={{
            root: classes["appbar-root"],
          }}
        >
          <Toolbar>
            <div className={classes["title"]}>
              <Link
                to="/"
                name={""}
                className={classes["link"]}
                onClick={handleLinkClick}
              >
                SOMAC
              </Link>
              <Link
                to="/users"
                name={"users"}
                className={classes["link"]}
                onClick={handleLinkClick}
              >
                Users
              </Link>
              <Link
                to="/orders"
                name={"orders"}
                className={classes["link"]}
                onClick={handleLinkClick}
              >
                Orders
              </Link>
              <Link
                to="/csv"
                name={"csv"}
                className={classes["link"]}
                onClick={handleLinkClick}
              >
                CSV
              </Link>
              <Link
                to="/fit-pictures"
                name={"fit-pictures"}
                className={classes["link"]}
                onClick={handleLinkClick}
              >
                Fit Pictures
              </Link>
            </div>
            <CoButton
              buttonText={"Log Out"}
              color={"navy"}
              handleClick={handleLogout}
              borderRadius={true}
            />
          </Toolbar>
        </AppBar>
      )}
      {isLoggingOut && <CoLoader message={"Logging out..."} />}
      {showIsEditingWarning && (
        <NavbarEditingWarning
          isEditingOrder={isEditingOrder}
          shouldGenerateOrderForm={shouldGenerateOrderForm}
          close={handleCloseWarning}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  "appbar-root": {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: `1px solid ${navyGray}`,
  },

  title: {
    flexGrow: 1,
    fontSize: "2rem",
    fontWeight: "bold",
  },

  link: {
    color: navy,
    textDecoration: "none",
    padding: `
			${theme.spacing(1)}px
			${theme.spacing(0.5)}px
		`,
    margin: `
			0
			${theme.spacing(1)}px
		`,
    "&:hover": {
      opacity: 0.6,
      textDecoration: "underline",
    },
  },
}));

export { Navbar };
