import React from "react";
import { makeStyles, DialogContent, Grid } from "@material-ui/core";
import { CoModalLayout, CoButton } from "./sharedComponents";
import { navy } from "./styles";

const CoSaveWarning = (props) => {
  const classes = useStyles();
  const { message, dontSave, cancel, save } = props;

  return (
    <CoModalLayout
      open={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth={"xs"}
      setNoHeight={true}
      zIndex={true}
    >
      <DialogContent
        classes={{
          root: classes["warning-root"],
        }}
      >
        <h2 className={classes["warning-header"]}>Warning!</h2>
        <p>{message}</p>
        <Grid container direction="row" justify="space-between">
          <div>
            <CoButton
              buttonText={"Don't save"}
              color={"white"}
              borderRadius={true}
              paddingX={true}
              handleClick={dontSave}
            />
          </div>
          <div>
            <CoButton
              buttonText={"Cancel"}
              color={"white"}
              borderRadius={true}
              marginX={true}
              handleClick={cancel}
            />
            <CoButton
              buttonText={"Save"}
              color={"navy"}
              borderRadius={true}
              handleClick={save}
            />
          </div>
        </Grid>
      </DialogContent>
    </CoModalLayout>
  );
};

const useStyles = makeStyles((theme) => ({
  "warning-root": {
    padding: theme.spacing(4),
    color: navy,
    fontSize: "1.4rem",
    "&:first-child": {
      paddingTop: theme.spacing(4),
    },
  },

  "warning-header": {
    margin: 0,
  },
}));

export { CoSaveWarning };
