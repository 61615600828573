const grayBlue = "rgb(238,239,241)";
const darkGrayBlue = "rgb(228,229,231)";
const darkerGrayBlue = "rgb(218,219,221)";
const navy = "rgb(15,28,60)";
const navyGray = "rgb(90,100,130)";
const mustard = "rgb(212,158,23)";
const maroon = "rgb(117,0,38)";

const tableLightGreen = "rgba(228,238,218)";
const tableLightBlue = "rgba(218,224,240)";
const tableGray = "rgb(216,216,216)";

const tableGreen = "rgb(180,206,145)";
const tableDarkGreen = "rgba(133,171,80)";
const tableBeach = "rgb(251,242,204)";
const tableLightOrange = "rgb(236,201,173)";
const tableOrange = "rgb(239,191,45)";
// const tableHotKeysSwitch = '#52d869'

const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "rgba(255,255,255, 0.4)",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "8px",
    backgroundColor: "rgba(198,198,198, 0.5)",
  },
  "-ms-overflow-style": "none",
};

const scrollbarNone = {
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
};

const truncate = {
  textOverflow: "ellipsis",
};

export {
  grayBlue,
  darkGrayBlue,
  darkerGrayBlue,
  navy,
  navyGray,
  mustard,
  maroon,
  scrollbarStyles,
  scrollbarNone,
  truncate,
  tableLightGreen,
  tableLightBlue,
  tableGray,
  tableGreen,
  tableDarkGreen,
  tableBeach,
  tableLightOrange,
  tableOrange,
  // tableHotKeysSwitch
};
