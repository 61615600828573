import { combineReducers } from "redux";
import { appReducer } from "./appReducer";
import { ordersReducer } from "./ordersReducer";
import { usersReducer } from "./usersReducer";

const rootReducer = combineReducers({
  app: appReducer,
  orders: ordersReducer,
  users: usersReducer,
});

export { rootReducer };
