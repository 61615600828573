import { firebase } from "../../firebase";

const login = async (email, password) => {
  try {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

const logout = async () => {
  try {
    return await firebase.auth().signOut();
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export { login, logout };
