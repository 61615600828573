import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const CoFractionToDecimalConversion = (props) => {
  const classes = useStyles();
  const { flexEnd } = props;
  return (
    <div
      className={clsx(classes["root"], { [classes["root-flex-end"]]: flexEnd })}
    >
      <div className={classes["conversion"]}>1/8 = 0.125 &nbsp;|</div>
      <div className={classes["conversion"]}>2/8 = 0.25 &nbsp;|</div>
      <div className={classes["conversion"]}>3/8 = 0.375 &nbsp;|</div>
      <div className={classes["conversion"]}>1/2 = 0.5 &nbsp;|</div>
      <div className={classes["conversion"]}>5/8 = 0.625 &nbsp;|</div>
      <div className={classes["conversion"]}>3/4 = 0.75 &nbsp;|</div>
      <div className={classes["conversion"]}>7/8 = 0.875</div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontWeight: "bold",
    fontSize: "1.4rem",
    margin: `
			${theme.spacing(1)}px
			0
		`,
  },

  "root-flex-end": {
    justifyContent: "flex-end",
  },

  conversion: {
    marginLeft: theme.spacing(1),
  },
}));

export { CoFractionToDecimalConversion };
