import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { firebase } from "../firebase";

import { Navbar } from "./layouts/Navbar";
import { SET_USER } from "../redux/actionTypes";
import { CoLoader } from "./shared/sharedComponents";
import { initialiseApp } from "../redux/actions/appActions";

const Home = React.lazy(() => import("./pages/home/Home"));
const Login = React.lazy(() => import("./pages/login/Login"));
const Orders = React.lazy(() => import("./pages/orders/Orders"));
const Order = React.lazy(() => import("./pages/orders/Order"));
// const OrderNew = React.lazy(() => import('./pages/orders/OrderNew'))
// const Order = React.lazy(() => import('./pages/orders/V2Order'))
const Users = React.lazy(() => import("./pages/users/Users"));
const User = React.lazy(() => import("./pages/users/User"));
const DebugPage = React.lazy(() => import("./pages/debug/DebugPage"));
const Csv = React.lazy(() => import("./pages/csv-automation/csv"));
const FitPictures = React.lazy(() =>
  import("./pages/fit-pictures/FitPictures")
);
// const Analytics = React.lazy(() => import('./pages/analytics/Analytics'))

const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.app.get("user"));

  useEffect(() => {
    const init = () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          dispatch({
            type: SET_USER,
            payload: user,
          });
          await dispatch(initialiseApp());
        }
        setIsLoading(false);
      });
    };
    init();
    return () => init;
  }, [dispatch]);

  return isLoading ? (
    <CoLoader message={"Page loading..."} />
  ) : (
    <Router>
      <>
        <Navbar />
        <Suspense fallback={<CoLoader message={"Preparing page..."} />}>
          <Switch>
            <Route exact path="/fit-pictures">
              <FitPictures />
            </Route>
            <Route exact path="/csv">
              <Csv />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <PrivateRoute user={user} path="/orders/:orderId">
              <Order />
            </PrivateRoute>
            {/*<PrivateRoute user={user} path='/orders/:orderId'><OrderNew /></PrivateRoute>*/}
            {/*<PrivateRoute user={user} path='/orders/:orderId'><OrderNewV2 /></PrivateRoute>*/}
            <PrivateRoute user={user} path="/orders">
              <Orders />
            </PrivateRoute>
            <PrivateRoute user={user} path="/users/:userId">
              <User />
            </PrivateRoute>
            <PrivateRoute user={user} path="/users">
              <Users />
            </PrivateRoute>
            <PrivateRoute user={user} path="/debug">
              <DebugPage />
            </PrivateRoute>
            {/*<PrivateRoute user={user} path='/analytics'><Analytics /></PrivateRoute>*/}
            <PrivateRoute user={user} path="/">
              <Home />
            </PrivateRoute>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Suspense>
      </>
    </Router>
  );
};

export default App;

const PrivateRoute = ({ children, user, ...rest }) => (
  <Route
    {...rest}
    render={() => (user ? children : <Redirect to="/login" />)}
  />
);
