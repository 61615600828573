import { SET_USERS } from "../actionTypes";

import { fromJS } from "immutable";

const initialState = fromJS({
  // TODO: PAGINATION
  users: [],
  // hasPreviousPage: false,
  // hasNextPage: false,
  // previousPageCursor: null,
  // nextPageCursor: null
});

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return state.set("users", fromJS(action.payload));
    // .set('hasPreviousPage', action.payload.hasPreviousPage)
    // .set('hasNextPage', action.payload.hasNextPage)
    // .set('previousPageCursor', action.payload.previousPageCursor)
    // .set('nextPageCursor', action.payload.nextPageCursor)

    default:
      return state;
  }
};

export { usersReducer };
