import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { CoModalLayout } from "./sharedComponents";
import { navy } from "./styles";

const CoLoader = (props) => {
  const classes = useStyles();
  const { message = "" } = props;

  return (
    <CoModalLayout
      open={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth={"xs"}
      setNoHeight={true}
    >
      <div className={classes["root"]}>
        <CircularProgress size={30} className={classes["progress"]} />
        {message !== "" && <div className={classes["message"]}>{message}</div>}
      </div>
    </CoModalLayout>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    textAlign: "center",
  },

  progress: {
    color: navy,
  },

  message: {
    marginTop: theme.spacing(2),
    fontSize: "1.4rem",
    textAlign: "center",
    color: navy,
  },
}));

export { CoLoader };
