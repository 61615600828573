import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { PriorityHigh } from "@material-ui/icons";

const CoWarningIcon = (props) => {
  const classes = useStyles();
  const { message } = props;

  return (
    <Tooltip
      title={message}
      placement="top"
      arrow
      classes={{
        tooltip: classes["tooltip"],
        arrow: classes["tooltip-arrow"],
      }}
    >
      <div className={classes["root"]}>
        <PriorityHigh className={classes["icon"]} />
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "1.4rem",
    height: "1.4rem",
    borderRadius: "2rem",
    color: "white",
    backgroundColor: "red",
    marginLeft: "0.8rem",
    cursor: "help",
    "&:hover": {
      backgroundColor: "red",
    },
  },

  icon: {
    fontSize: "1rem",
  },

  tooltip: {
    fontSize: "1.2rem",
    backgroundColor: "red",
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    padding: "0.8rem",
  },

  "tooltip-arrow": {
    "&::before": {
      color: "red",
    },
  },
}));

export { CoWarningIcon };
