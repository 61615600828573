import React from "react";
import { makeStyles, DialogContent, Grid } from "@material-ui/core";
import { CoModalLayout, CoButton } from "./sharedComponents";
import { navy } from "./styles";

const CoWarning = (props) => {
  const classes = useStyles();
  const { messageOne, messageTwo, agreeText, disagreeText, agree, disagree } =
    props;

  return (
    <CoModalLayout
      open={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth={"xs"}
      setNoHeight={true}
      // zIndex={true}
    >
      <DialogContent
        classes={{
          root: classes["warning-root"],
        }}
      >
        <h2 className={classes["warning-header"]}>Warning!</h2>
        {messageOne && <p>{messageOne}</p>}
        {messageTwo && <p>{messageTwo}</p>}
        <Grid container direction="row" justify="space-between">
          <CoButton
            buttonText={disagreeText}
            color={"white"}
            borderRadius={true}
            handleClick={disagree}
          />
          <CoButton
            buttonText={agreeText}
            color={"navy"}
            borderRadius={true}
            handleClick={agree}
          />
        </Grid>
      </DialogContent>
    </CoModalLayout>
  );
};

const useStyles = makeStyles((theme) => ({
  "warning-root": {
    padding: theme.spacing(4),
    color: navy,
    lineHeight: 1.5,
    fontSize: "1.4rem",
    "&:first-child": {
      paddingTop: theme.spacing(4),
    },
  },

  "warning-header": {
    margin: 0,
  },
}));

export { CoWarning };
