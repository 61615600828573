import React from "react";
import clsx from "clsx";
import { makeStyles, Button } from "@material-ui/core";
import { navy, darkGrayBlue, darkerGrayBlue } from "./styles";

const CoButton = (props) => {
  const classes = useStyles();
  const {
    type = "button",
    buttonText,
    color,
    handleClick,
    fullWidth = false,
    disabled = false,
    name = "",
    value = "",
    borderRadius = false,
    marginX = false,
    marginTop = false,
    marginBottom = false,
    paddingX = false,
    padding = false,
    textUnderline = false,
    toggleHoverColor = false,
    bold = true,
    capitalise = false,
  } = props;

  return (
    <Button
      type={type}
      className={clsx(
        classes["button"],
        classes[color],
        { [classes["button-bold"]]: bold },
        { [classes["border-radius"]]: borderRadius },
        { [classes["margin-x"]]: marginX },
        { [classes["margin-top"]]: marginTop },
        { [classes["margin-bottom"]]: marginBottom },
        { [classes["paddingX"]]: paddingX },
        { [classes["padding"]]: padding },
        { [classes["text-underline"]]: textUnderline },
        { [classes[`${color}-hover`]]: toggleHoverColor },
        { [classes["text-capitalise"]]: capitalise }
      )}
      disableFocusRipple
      disableRipple
      onClick={handleClick}
      fullWidth={fullWidth}
      disabled={disabled}
      name={name}
      value={value}
      classes={{
        disabled: classes["button-disabled"],
      }}
    >
      {buttonText}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 0,
    fontSize: "1.4rem",
    textTransform: "none",
  },

  "button-bold": {
    fontWeight: "bold",
  },

  "button-disabled": {
    cursor: "not-allowed !important",
    color: "white !important",
    pointerEvents: "unset !important",
    opacity: 0.5,
    "&:hover": {
      backgroundColor: `${navy} !important`,
    },
  },

  navy: {
    color: "white",
    border: `1px solid ${navy}`,
    backgroundColor: navy,
    "&:hover": {
      backgroundColor: navy,
    },
  },

  gray: {
    color: "white",
    border: `1px solid grey`,
    backgroundColor: "grey",
    "&:hover": {
      backgroundColor: navy,
    },
  },

  "navy-hover": {
    "&:hover": {
      color: navy,
      borderColor: darkerGrayBlue,
      backgroundColor: darkerGrayBlue,
    },
  },

  transparent: {
    color: navy,
    fontWeight: "bold",
    border: `1px solid ${navy}`,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  white: {
    color: navy,
    border: `1px solid ${darkGrayBlue}`,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },

  "border-radius": {
    borderRadius: theme.spacing(1),
  },

  "border-none": {
    borderColor: "transparent",
  },

  "margin-x": {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  "margin-top": {
    marginTop: "1rem",
  },

  "margin-bottom": {
    marginBottom: "1rem",
  },

  paddingX: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },

  padding: {
    padding: theme.spacing(1.5),
  },

  "text-underline": {
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  "text-capitalise": {
    textTransform: "capitalize !important",
  },
}));

export { CoButton };
