import _ from "underscore";
import { HYDRATE_APP_STATE } from "../actionTypes";
import { axiosInstance } from "../../utils/axios";

const initialiseApp = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(
      "/contentful/products/config?fitting=true"
    );
    dispatch({
      type: HYDRATE_APP_STATE,
      payload: _.omit(response, "currencyConversion"),
    });
  } catch (error) {
    return error;
  }
};

export { initialiseApp };
