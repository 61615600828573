import React from "react";
import clsx from "clsx";
import { makeStyles, OutlinedInput } from "@material-ui/core";
import { navy, truncate } from "./styles";

const CoOutlinedInput = (props) => {
  const classes = useStyles();
  const {
    type = "text",
    name,
    value,
    disabled = false,
    fullWidth = true,
    placeholder = "",
    inputChange,
    dataMeta = "",
    border = true,
    borderRadius = true,
    textUppercase = false,
    textCapitalize = false,
    textCenter = false,
  } = props;

  return (
    <OutlinedInput
      name={name}
      value={value}
      fullWidth={fullWidth}
      placeholder={placeholder}
      classes={{
        root: clsx(classes["input-root"], {
          [classes["input-root-radius-none"]]: !borderRadius,
        }),
        input: clsx(
          classes["input"],
          { [classes["input-uppercase"]]: textUppercase },
          { [classes["input-capitalize"]]: textCapitalize },
          { [classes["input-center"]]: textCenter }
        ),
        notchedOutline: clsx(classes["input-outline"], {
          [classes["input-outline-border-none"]]: !border,
        }),
        disabled: classes["input-disabled"],
      }}
      type={type}
      onChange={inputChange}
      disabled={disabled}
      inputProps={{ "data-meta": dataMeta }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  "input-root": {
    fontSize: "1.4rem",
    backgroundColor: "white",
    fontWeight: "normal",
  },

  "input-root-radius-none": {
    borderRadius: 0,
  },

  input: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: navy,
    ...truncate,
    "&::placeholder": {
      color: navy,
      fontStyle: "italic",
    },
  },

  "input-uppercase": {
    textTransform: "uppercase",
  },

  "input-capitalize": {
    textTransform: "capitalize",
  },

  "input-center": {
    textAlign: "center",
  },

  "input-disabled": {
    cursor: "not-allowed",
  },

  "input-outline": {
    borderRadius: 0,
    border: "1px solid rgba(0,0,0,0.23) !important",
  },

  "input-outline-border-none": {
    borderColor: "transparent !important",
  },
}));

export { CoOutlinedInput };
