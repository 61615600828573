import React from "react";
import clsx from "clsx";
import { makeStyles, Box, Button } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { navy } from "./styles";

const CoPagination = (props) => {
  const classes = useStyles();
  const {
    hasPreviousPage,
    hasNextPage,
    firstItemCursor,
    lastItemCursor,

    previousPage,
    nextPage,
  } = props;

  return (
    <Box className={classes["pagination"]}>
      <Button
        disableFocusRipple
        disableRipple
        disabled={!hasPreviousPage}
        value={firstItemCursor}
        onClick={previousPage}
        className={classes["pagination-button"]}
        endIcon={
          <KeyboardArrowLeft
            className={clsx(classes["pagination-icon"], {
              [classes["pagination-icon-disabled"]]: !hasPreviousPage,
            })}
          />
        }
        data-is-next={false}
      >
        Previous Page
      </Button>
      <Button
        disableFocusRipple
        disableRipple
        disabled={!hasNextPage}
        value={lastItemCursor}
        onClick={nextPage}
        className={classes["pagination-button"]}
        startIcon={
          <KeyboardArrowRight
            className={clsx(classes["pagination-icon"], {
              [classes["pagination-icon-disabled"]]: !hasNextPage,
            })}
          />
        }
        data-is-next={true}
      >
        Next Page
      </Button>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },

  "pagination-button": {
    fontSize: "1.4rem",
    fontWeight: "bold",
    textTransform: "none",
    color: navy,
    textDecoration: "underline",
    backgroundColor: "transparent",
    "&:hover": {
      color: "white",
      backgroundColor: navy,
      textDecoration: "underline",
    },
  },

  "pagination-icon": {
    fontSize: "2.6rem !important",
  },

  "pagination-icon-disabled": {
    color: "#00000042",
  },
}));

export { CoPagination };
