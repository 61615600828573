import firebase from "firebase/app";
import "firebase/auth";
// import { firebaseDevConfig } from "./.firebase.dev.js";
import { firebaseProdConfig } from "./.firebase.prod.js";

// const firebaseConfig =
// process.env.NODE_ENV === "development"
// ? firebaseDevConfig
// : firebaseProdConfig;

firebase.initializeApp(firebaseProdConfig);

export { firebase };
