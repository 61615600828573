import { HYDRATE_APP_STATE, SET_USER } from "../actionTypes";

import { fromJS } from "immutable";

const initialState = fromJS({
  productsConfig: {},
  user: null,
});

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE_APP_STATE:
      return state.set("productsConfig", fromJS(action.payload));

    case SET_USER:
      return state.set("user", action.payload.email);

    default:
      return state;
  }
};

export { appReducer };
