import React from "react";

import { makeStyles, Box } from "@material-ui/core";
import { ArrowDownward } from "@material-ui/icons";

const CoScrollForMore = (props) => {
  const classes = useStyles();
  const {
    top = "unset",
    bottom = "unset",
    right = "unset",
    left = "unset",
  } = props;

  return (
    <Box
      className={classes["scroll-for-more"]}
      style={{
        top,
        bottom,
        right,
        left,
      }}
    >
      <Box className={classes["scroll-for-more-content"]}>
        <Box px={0.5}>Scroll for more</Box>
        <ArrowDownward className={classes["scroll-for-more-icon"]} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  "scroll-for-more": {
    width: "100%",
    height: "3rem",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    zIndex: 1,
  },

  "scroll-for-more-content": {
    width: "15rem",
    height: "100%",
    color: "white",
    backgroundColor: "rgba(0,0,0,0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.4rem",
    fontWeight: "bold",
    borderRadius: theme.spacing(1.5),
  },

  "scroll-for-more-icon": {
    fontSize: "2rem",
  },
}));

export { CoScrollForMore };
