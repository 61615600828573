import React from "react";
import { makeStyles } from "@material-ui/core";
import { CoButton, CoModalLayout } from "../shared/sharedComponents";
import { navy } from "../shared/styles";

const NavbarEditingWarning = (props) => {
  const classes = useStyles();
  const { isEditingOrder, shouldGenerateOrderForm, close } = props;

  return (
    <CoModalLayout open={true} maxWidth={"xs"} setNoHeight={true}>
      <div className={classes["root"]}>
        <h2 className={classes["header"]}>Warning!</h2>
        {/*<p>You are currently making edits to the order. Please save your changes, if any, first.</p>*/}
        {/* use this when save resumes */}
        {/*
				{
					shouldGenerateOrderForm && (
						isEditingOrder ? (
							<div>
								<p>You are currently editing this order.</p>
								<p>If the edits you have made are final, select "Okay" followed by "Finish Edit" and proceed to generate order form.</p>
							</div>
						) : (
							<div>
								<p>You have made edits to this order.</p>
								<p>If the edits you have made are final, proceed to generate the order form. Otherwise, your edits will be lost.</p>
							</div>
						)
					)
				}
				*/}
        {isEditingOrder && !shouldGenerateOrderForm && (
          <div>
            <p>
              You are navigating away from this page while editing this order.
            </p>
            <p>
              If the edits you have made are final, select "Okay" followed by
              "Finish Edit" and proceed to generate order form.
            </p>
            <p>
              If no changes have been made or you would like to undo all changes
              in this current edit, select "Okay" followed by "Cancel" before
              navigating away from this page.
            </p>
          </div>
        )}
        {!isEditingOrder && shouldGenerateOrderForm && (
          <div>
            <p>
              You are navigating away from this page but have already made edits
              to this order.
            </p>
            <p>
              If the edits you have made are final, proceed to generate order
              form. Otherwise, your edits will be lost.
            </p>
          </div>
        )}
        <div className={classes["button"]}>
          <CoButton
            buttonText={"Okay"}
            color={"navy"}
            borderRadius={true}
            handleClick={close}
          />
        </div>
      </div>
    </CoModalLayout>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    color: navy,
    fontSize: "1.4rem",
    "&:first-child": {
      paddingTop: theme.spacing(4),
    },
  },

  header: {
    margin: 0,
  },

  button: {
    textAlign: "center",
  },
}));

export { NavbarEditingWarning };
