import {
  UPDATE_IS_EDITING_ORDER,
  UPDATE_SHOULD_GENERATE_ORDER_FORM,
} from "../actionTypes";
import { fromJS } from "immutable";

const initialState = fromJS({
  isEditingOrder: false,
  shouldGenerateOrderForm: false,
});

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_IS_EDITING_ORDER:
      return state.set("isEditingOrder", action.payload);

    case UPDATE_SHOULD_GENERATE_ORDER_FORM:
      return state.set("shouldGenerateOrderForm", action.payload);

    default:
      return state;
  }
};

export { ordersReducer };
